import { instance } from '../api/ApiRequest';
import { serverUrls } from '../variables';


export const createNewBrand = async (payload: any) =>
  await instance.post(
    `${serverUrls.reportingBrand}brands/`,
    payload,
    { headers: { "Content-Type": "multipart/form-data" } },
  );



export const reSyncBrandAirTable = async (id?: number, page?:string) => {
  const urlStr =  id ?  `${serverUrls.reportingBrand}brands/resync/?franchisor_id=${id}&page=${page ?? ''}`
    : `${serverUrls.reportingBrand}brands/resync/?page=${page ?? ''}` ;

  return await instance.get(urlStr);
};



export const getWarnings = async (id?:number, page:string = '') => {
  const urlStr =   id ? `${serverUrls.reportingBrand}brands/warnings/?franchisor_id=${id}&page=${page ?? ''}`
    : `${serverUrls.reportingBrand}brands/warnings/?page=${page ?? ''}` ;

  return  await instance.get(urlStr);
};
